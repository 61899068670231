import request from "@/utils/request";

export default class PunishmentService {
    /**
     * 获取学生评语列表
     */
    static async getList(params) {
        const resp = await request({
            url: '/v2/school_api/student/evaluation_conduct/index',
            params,
        })
        return resp.data
    }
}
