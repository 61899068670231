<template>
	<div id="card" v-loading="loading">
		<div class="content" id="prints" ref="print">
			<div class="u-f-justify title">广东省中等职业学校学生学籍卡</div>
			<div class="u-f-justify" style="margin-top: 10px;">
				<div class="u-font-blod u-m-r-20">学校盖章:惠州市博罗中等专业学校</div>
				<div class="u-font-blod u-m-r-20">办学类型:{{info.is_fill_day?'全日制':'非全日制'}}</div>
				<div class="u-font-blod u-m-r-20">专业:{{info.zy_name}}</div>
				<div class="u-font-blod u-m-r-20">年级:{{info.grade_name}}</div>
				<div class="u-font-blod u-m-r-20">班级:{{info.class_name}}</div>
				<div class="u-font-blod u-m-r-20">学制:{{info.school_year}}年</div>
				<div class="u-font-blod u-m-r-20">学生证编号:{{info.cert_no}}</div>
				<div class="u-font-blod u-m-r-20">学籍号:{{info.student_num}}</div>
			</div>
			<table style="margin-top: 10px;border-top: 1px solid #000;border-left: 1px solid #000;border-spacing: 0;">
				<tr>
					<td class="td" style="width: 40px;" align="center">姓名</td>
					<td class="td" style="width: 60px;" align="center">{{info.realname}}</td>
					<td class="td" style="width: 40px;" align="center">性别</td>
					<td class="td" style="width: 60px;" align="center">{{info.sex==1?'男':'女'}}</td>
					<td class="td" style="width: 60px;" align="center">民族</td>
					<td class="td" style="width: 50px;" align="center">{{info.nation}}</td>
					<td class="td" style="width: 80px;" align="center">入学时间</td>
					<td class="td" style="width: 60px;" align="center">{{info.add_date}}</td>
					<td class="td" style="width: 80px;" align="center">出生日期</td>
					<td class="td" style="width: 80px;" align="center">{{info.birthday|date('YYYY-MM-DD')}}</td>
					<td class="td" style="width: 80px;" align="center">政治面貌</td>
					<td class="td" style="width: 60px;" align="center">
            <template v-if="info.identity === 1">群众</template>
            <template v-else-if="info.identity === 2">少先队员</template>
            <template v-else-if="info.identity === 3">团员</template>
            <template v-else>党员</template>
          </td>
					<td class="td" style="width: 60px;" align="center">籍贯</td>
					<td class="td" style="width: 180px;" align="center">{{info.native_place}}</td>
					<td class="td" style="width: 100px;" align="center">入学文化程度</td>
					<td class="td" style="width: 50px;" align="center">初中</td>
					<td class="td" style="width: 80px;" align="center">毕业证编号</td>
					<td class="td" style="width: 80px;" align="center">
            <template v-if="info.graduation">
              {{info.graduation.graduation_no}}
            </template>
          </td>
					<td class="td" style="width: 80px;"  rowspan="3" align="center"></td>
				</tr>
				<tr>
					<td class="td" style="width: 140px;" colspan="3" align="center">家庭详细地址</td>
					<td class="td" style="width: 290px;"colspan="5" align="center">
            {{info.address}}
          </td>
					<td class="td" style="width: 80px;" align="center">邮政编码</td>
					<td class="td" style="width: 80px;" align="center">{{info.zip_code}}</td>
					<td class="td" style="width: 80px;" align="center">联系电话</td>
					<td class="td" style="width: 120px;" colspan="2" align="center">{{info.phone}}</td>
					<td class="td" style="width: 180px;" align="center">毕业时间及去向</td>
					<td class="td" style="width: 310px;"  colspan="4" align="center">
            <template v-if="info.graduation">
              {{info.graduation.evolve}}
            </template>
          </td>
				</tr>
				<tr>
					<td class="td" colspan="2" align="center">入学考试成绩</td>
					<td class="td" rowspan="6" align="center" style="padding: 0 10px;">个人简历</td>
					<td class="td" colspan="2" align="center">何年月至何年月</td>
					<td class="td" colspan="4" align="center">在何地何学校或何单位何职务</td>
					<td class="td" colspan="6" align="center">学年操行评语</td>
					<td class="td" colspan="3" align="center">毕业鉴定</td>
				</tr>
				<tr><td class="td" align="center">总分</td>
					<td class="td" align="center">{{totalScore}}</td>
					<td class="td" colspan="2" align="center"></td>
					<td class="td" colspan="4" align="center"></td>
					<td class="td" rowspan="7" align="center">第一学年</td>
					<td class="td" colspan="5" rowspan="5" align="center" style="border-bottom: none;">
            <div class="evaluation-content">
              <p>
                {{evaluationConducts|get('1#1.evaluate')}}
              </p>
              <p>
                {{evaluationConducts|get('1#2.evaluate')}}
              </p>
            </div>
          </td>
					<td class="td" colspan="4" rowspan="13" align="center"></td>
				</tr>
				<tr><td class="td" align="center">{{enterScore|get('[0].name')}}</td>
					<td class="td" align="center">{{enterScore|get('[0].score')}}</td>
					<td class="td" colspan="2" align="center"></td>
					<td class="td" colspan="4" align="center"></td>
				</tr>
				<tr><td class="td" align="center">{{enterScore|get('[1].name')}}</td>
					<td class="td" align="center">{{enterScore|get('[1].score')}}</td>
					<td class="td" colspan="2" align="center"></td>
					<td class="td" colspan="4" align="center"></td>
				</tr>
				<tr><td class="td" align="center">{{enterScore|get('[2].name')}}</td>
					<td class="td" align="center">{{enterScore|get('[2].score')}}</td>
					<td class="td" colspan="2" align="center"></td>
					<td class="td" colspan="4" align="center"></td>
				</tr>
				<tr><td class="td" align="center">{{enterScore|get('[3].name')}}</td>
					<td class="td" align="center">{{enterScore|get('[3].score')}}</td>
					<td class="td" colspan="2" align="center"></td>
					<td class="td" colspan="4" align="center"></td>
				</tr>
				<tr>
					<td class="td" align="center">{{enterScore|get('[4].name')}}</td>
					<td class="td" align="center">{{enterScore|get('[4].score')}}</td>
					<td class="td" rowspan="7" align="center" style="padding: 0 10px;">家庭主要成员</td>
					<td class="td" align="center">称谓</td>
					<td class="td" align="center">姓名</td>
					<td class="td" align="center">年龄</td>
					<td class="td" align="center">政治面貌</td>
					<td class="td" colspan="2" align="center">工作单位和职务</td>
					<td class="td" align="center" style="border-top: 1px solid #000;">学期</td>
					<td class="td" align="center" style="border-top: 1px solid #000;">第一学期</td>
					<td class="td" align="center" style="border-top: 1px solid #000;">第二学期</td>
					<td class="td" colspan="2" align="center" style="border-bottom: none;"></td>
				</tr>
				<tr>
					<td class="td" align="center">{{enterScore|get('[5].name')}}</td>
					<td class="td" align="center">{{enterScore|get('[5].score')}}</td>
					<td class="td" align="center">{{info|get('relationship1')}}</td>
					<td class="td" align="center">{{info|get('name1')}}</td>
					<td class="td" align="center">{{info|get('sex1')}}</td>
					<td class="td" align="center">
            <template v-if="info.identity1 === 1">党员</template>
            <template v-else-if="info.identity2 === 2">群众</template>
          </td>
					<td class="td" colspan="2" align="center">{{info|get('work_unit1')}}</td>
					<td class="td" align="center">操行</td>
					<td class="td" align="center">
            {{evaluationConducts|get('1#1.type')|evaluationText}}
          </td>
					<td class="td" align="center">
            {{evaluationConducts|get('1#2.type')|evaluationText}}
          </td>
					<td class="td" colspan="2" align="center"></td>
				</tr>
				<tr>
					<td class="td" align="center">{{enterScore|get('[6].name')}}</td>
					<td class="td" align="center">{{enterScore|get('[6].score')}}</td>
          <td class="td" align="center">{{info|get('relationship2')}}</td>
          <td class="td" align="center">{{info|get('name2')}}</td>
          <td class="td" align="center">{{info|get('sex2')}}</td>
          <td class="td" align="center">
            <template v-if="info.identity2 === 1">党员</template>
            <template v-else-if="info.identity2 === 2">群众</template>
          </td>
					<td class="td" colspan="2" align="center">{{info|get('work_unit2')}}</td>
					<td class="td" rowspan="8" align="center">第二学年</td>
					<td class="td" colspan="5" rowspan="5" align="center" style="border-bottom: none;">
            <div class="evaluation-content">
              <p>
                {{evaluationConducts|get('2#1.evaluate')}}
              </p>
              <p>
                {{evaluationConducts|get('2#2.evaluate')}}
              </p>
            </div>
          </td>
				</tr>
				<tr>
					<td class="td" align="center">{{enterScore|get('[7].name')}}</td>
					<td class="td" align="center">{{enterScore|get('[7].score')}}</td>
					<td class="td" align="center"></td>
					<td class="td" align="center"></td>
					<td class="td" align="center"></td>
					<td class="td" align="center"></td>
					<td class="td" colspan="2" align="center"></td>
				</tr>
				<tr>
					<td class="td" align="center">{{enterScore|get('[8].name')}}</td>
					<td class="td" align="center">{{enterScore|get('[8].score')}}</td>
					<td class="td" align="center"></td>
					<td class="td" align="center"></td>
					<td class="td" align="center"></td>
					<td class="td" align="center"></td>
					<td class="td" colspan="2" align="center"></td>
				</tr>
				<tr>
					<td class="td" align="center">{{enterScore|get('[9].name')}}</td>
					<td class="td" align="center">{{enterScore|get('[9].score')}}</td>
					<td class="td" align="center"></td>
					<td class="td" align="center"></td>
					<td class="td" align="center"></td>
					<td class="td" align="center"></td>
					<td class="td" colspan="2" align="center"></td>
				</tr>
				<tr>
					<td class="td" align="center"></td>
					<td class="td" align="center"></td>
					<td class="td" align="center"></td>
					<td class="td" align="center"></td>
					<td class="td" align="center"></td>
					<td class="td" align="center"></td>
					<td class="td" colspan="2" align="center"></td>
				</tr>
				<tr>
					<td class="td" colspan="5" align="center">奖励、考证记录</td>
					<td class="td" colspan="4" align="center">处分记录</td>
					<td class="td" colspan="5" align="center" style="border-bottom: none;"></td>
				</tr>
				<tr>
					<td class="td" colspan="5" rowspan="12" align="center"></td>
					<td class="td" colspan="4" rowspan="12">
            <div class="box-content">
              <ol>
                <template v-for="item in punishments">
                  <li :key="item.id">
                    {{item.title}} ({{item.content}})
                  </li>
                </template>
              </ol>
            </div>
          </td>
					<td class="td" align="center" style="border-top: 1px solid #000;">学期</td>
					<td class="td" align="center" style="border-top: 1px solid #000;">第一学期</td>
					<td class="td" align="center" style="border-top: 1px solid #000;">第二学期</td>
					<td class="td" colspan="2" align="center" style="border-bottom: none;"></td>
					<td class="td" align="center" rowspan="10" style="padding: 0 15px;">学校意见</td>
					<td class="td" colspan="3" rowspan="10" align="center"></td>
				</tr>
				<tr>
					<td class="td" align="center">操行</td>
          <td class="td" align="center">
            {{evaluationConducts|get('2#1.type')|evaluationText}}
          </td>
          <td class="td" align="center">
            {{evaluationConducts|get('2#2.type')|evaluationText}}
          </td>
					<td class="td" colspan="2" align="center"></td>
				</tr>
				<tr>
					<td class="td" rowspan="10" align="center">第三学年</td>
					<td class="td" colspan="5" rowspan="8" align="center" style="border-bottom: none;">
            <div class="evaluation-content">
              <p>
                {{evaluationConducts|get('3#1.evaluate')}}
              </p>
              <p>
                {{evaluationConducts|get('3#2.evaluate')}}
              </p>
            </div>
          </td>
				</tr>
				<tr style="height: 30px;"></tr><tr style="height: 30px;"></tr><tr style="height: 30px;"></tr><tr style="height: 30px;"></tr><tr style="height: 30px;"></tr><tr style="height: 30px;"></tr><tr style="height: 30px;"></tr>
				<tr>
					<td class="td" align="center" style="border-top: 1px solid #000;">学期</td>
					<td class="td" align="center" style="border-top: 1px solid #000;">第一学期</td>
					<td class="td" align="center" style="border-top: 1px solid #000;">第二学期</td>
					<td class="td" colspan="2" align="center" style="border-bottom: none;"></td>
					<td class="td" align="center" rowspan="2" style="padding: 0 15px;">备注</td>
					<td class="td" colspan="3" rowspan="2" align="center"></td>
				</tr>
				<tr>
					<td class="td" align="center">操行</td>
          <td class="td" align="center">
            {{evaluationConducts|get('3#1.type')|evaluationText}}
          </td>
          <td class="td" align="center">
            {{evaluationConducts|get('3#2.type')|evaluationText}}
          </td>
					<td class="td" colspan="2" align="center"></td>
				</tr>
			</table>

			<div class="u-f">
				<div class="u-f1">
					<table style="margin-top: 10px;border-top: 1px solid #000;border-left: 1px solid #000;border-spacing: 0;">
						<tr>
							<td class="td" style="width: 150px;" align="center">学期</td>
							<td class="td" style="width: 200px;" align="center">课程名称</td>
							<td class="td" align="center"  style="width: 80px;">科目类型</td>
							<td class="td" align="center" style="width: 80px;">学期<br>总评成绩</td>
							<td class="td" align="center" style="width: 80px;">补考后<br>总评成绩</td>
							<td class="td" align="center" style="width: 80px;">重修后<br>总评成绩</td>
							<td class="td" align="center" style="width: 80px;">取得<br>学分</td>
						</tr>
						<tr v-for="(item,index) in info.exam_results" :key="index">
							<td class="td" style="width: 150px;" align="center">{{item|get('semester.name')}}</td>
							<td class="td" style="width: 200px;" align="center">{{item|get('discipline.name')}}</td>
							<td class="td" align="center"  style="width: 80px;">{{item|get('discipline.discipline_type.name')}}</td>
							<td class="td" align="center" style="width: 80px;">{{item|get('score')}}</td>
							<td class="td" align="center" style="width: 80px;"></td>
							<td class="td" align="center" style="width: 80px;"></td>
							<td class="td" align="center" style="width: 80px;"></td>
						</tr>
					</table>
				</div>
				<div class="u-f1" style="margin-left: 15px;">
					<table style="margin-top: 10px;border-top: 1px solid #000;border-left: 1px solid #000;border-spacing: 0;">
						<tr>
							<td class="td" style="width: 150px;" align="center">学期</td>
							<td class="td" style="width: 200px;" align="center">课程名称</td>
							<td class="td" align="center"  style="width: 80px;">科目类型</td>
							<td class="td" align="center" style="width: 80px;">学期<br>总评成绩</td>
							<td class="td" align="center" style="width: 80px;">补考后<br>总评成绩</td>
							<td class="td" align="center" style="width: 80px;">重修后<br>总评成绩</td>
							<td class="td" align="center" style="width: 80px;">取得<br>学分</td>
						</tr>
						<tr v-for="(item,index) in info.exam_results" :key="index">
							<td class="td" style="width: 150px;" align="center"></td>
							<td class="td" style="width: 200px;" align="center"></td>
							<td class="td" align="center"  style="width: 80px;"></td>
							<td class="td" align="center" style="width: 80px;"></td>
							<td class="td" align="center" style="width: 80px;"></td>
							<td class="td" align="center" style="width: 80px;"></td>
							<td class="td" align="center" style="width: 80px;"></td>
						</tr>
					</table>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import * as Api from "@/api/v2/student";
import PunishmentService from "@/api/v2/punishment";
import EvaluationConductService from "@/api/v2/evaluation_conduct";

export default {
	props: {},
	data() {
		return {
			loading: true,
			id: '',
			info:'',
      enterScore: [],
      punishments: [],
      evaluationConducts: [],
		};
	},
  filters: {
    evaluationText(val) {
      const newVal = Number(val)
      if (newVal === 1) {
        return '优'
      }
      if (newVal === 2) {
        return '良'
      }
      if (newVal === 3) {
        return '合格'
      }
      if (newVal === 4) {
        return '不合格'
      }
      return ''
    }
  },
  computed: {
    totalScore() {
      return _.sumBy(this.enterScore, (e) => e.score)
    }
  },
	mounted() {
		this.id = this.$route.query.id;
		// this.$print(this.$refs.print)
    this.getEnterScore()
    this.getPunishment()
    this.getEvaluationConduct()
		this.getInfo()
	},
	methods: {
    /**
     * 获取科目列表
     */
    async getEnterScore() {
      this.enterScore = await Api.getEnterScore(Number(this.id))
    },
    /**
     * 获取处分列表
     */
    async getPunishment() {
      const resp = await PunishmentService.getList({
        student_id: this.id,
        status: 2, // 2=通过的
        limit: 100,
      })
      this.punishments = resp.rows
    },
    /**
     * 获取评语列表
     */
    async getEvaluationConduct() {
      const resp = await EvaluationConductService.getList({
        student_id: this.id,
        status: 2, // 2=通过的
        limit: 100,
      })
      this.evaluationConducts = resp
    },
		getInfo(){
			this.$api.student.studentCard({id:this.id}).then(res=>{
				if(res.data.code===1){
					this.info = res.data.data;
				}
			}).finally(() => {
        this.loading = false
      })
		}
	}
};
</script>

<style lang="scss" scope>
#card {
	.head {
		background-color: #4998ff;
		padding: 15px 20px;
		div {
			color: #fff;
			font-size: 16px;
		}
	}
	.content {
		background-color: #ffffff;
		margin: 20px auto;
		width: 1400px;
		overflow-y: auto;
		.title{
			font-weight: bold;
			font-size: 20px;
			// letter-spacing:30px;
		}
		.input{
			border: none;
			border-bottom: 1px solid #000;
		}
		.input1{
			width: 100px;
		}
		.photo{
			width: 120px;
			height: 150px;
			border: 1px solid #000;
		}
		.key{
			padding:0 5px;
			border-right: 1px solid #000;
			height: 25px;
			line-height: 25px;
		}
		.val{
			padding:0 5px;
			width: 120px;
			border-right: 1px solid #000;
			height: 25px;
			line-height: 25px;
		}
	}
}
.u-font-blod{
	font-weight: bold;
}
.u-m-r-20{
	margin-right: 20px;
}
.td{
	height: 30px;
	border-right: 1px solid #000;
	border-bottom: 1px solid #000;
}

.box-content {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  ol {
    margin: 10px 0;
    padding-left: 20px;
  }
  li {
    margin: 0;
    padding: 0;
  }
}
.tags {
  display: flex;
  align-items: center;
}
.tag {
  display: inline-block;
  margin-right: 4px;
}

.evaluation-content {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  padding: 10px;
  box-sizing: border-box;
  text-align: left;
}
</style>
