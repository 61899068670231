import request from "@/utils/request";

/**
 * 获取入学各科分数
 */
export const getEnterScore = async (studentId) => {
    const resp = await request({
        url: '/v2/school_api/student/enter_test/enter_score',
        params: {
            student_id: studentId,
        }
    })
    return resp.data
}

/**
 * 获取班级学生列表
 */
export const getGradeClassStudents = async (ids) => {
    const resp = await request({
        url: '/v2/school_api/grade/getGradeClassStudent',
        params: {
            ids,
        }
    })
    return resp.data
}

/**
 * 获取指定学生信息
 */
export const getDetail = async (id) => {
    const resp = await request({
        url: '/v2/school_api/student/student/detail',
        params: {
            id,
        }
    })
    return resp.data
}
